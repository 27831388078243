import React, { ReactNode } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { usePage } from '@/hooks'
import Link from 'next/link'
import '.././pages/os/main.less'
import './swiper.less'

let list: any = [
  {
    bImg: 'https://img3.dian.so/lhc/2022/09/01/1920w_450h_00C761662025003.png',
    sImg: 'https://img3.dian.so/lhc/2022/09/02/375w_668h_977261662096683.png',
    link: '/os/agentRecruit',
    title: '马上加盟获取利润列表',
  },
  {
    bImg: 'https://fed.dian.so/image/3c577cd7c87bb40c526331acace2014c.png',
    sImg: 'https://fed.dian.so/image/e20d33c6d004ed373e428817737188eb.png',
    link: '/os/agentRecruit',
    title: '马上加盟获取利润列表',
  },
  {
    bImg: 'https://fed.dian.so/image/2cbac4a37ec536cff279e2970e37d356.png',
    sImg: 'https://fed.dian.so/image/c8777ea8ba1cac547e7a15b78e768f50.png',
    link: '/os/agentRecruit',
    title: '马上加盟获取利润列表',
  },
]
interface IPropsModel {
  children?: ReactNode;
  title?: string;
  bImgs?: string[];
  sImgs?: string[];
}
const AutoPlaySwipeableViews: any = autoPlay(SwipeableViews)
const init: Record<string, any> = {
  state: {
    currentIndex: 0,
    color: '',
    paginationList: [0, 1, 2],
  },
  changeIndex (currentIndex: any) {
    this.setState({
      currentIndex,
    })
  },
}

function Index ({ bImgs, sImgs }: IPropsModel): JSX.Element {
  const [state, events] = usePage(init)
  const { currentIndex } = state
  const { changeIndex } = events
  list = list.map((item: any, i: number) => ({
    ...item,
    bImg: bImgs?.[i] || item.bImg,
    sImg: sImgs?.[i] || item.sImg,
  }))

  return (
    <div className="swiper-container">
      <div className="swiper-slide">
        <AutoPlaySwipeableViews enableMouseEvents index={currentIndex} onChangeIndex={changeIndex}>
          {list?.map((item: any, index: number) => {
            return (
              <div key={index} className="home-image">
                <img className="img hidden-xs" src={item.bImg} alt="" />
                <img className="img visible-xs" src={item.sImg} alt="" />
                <div className="btn-box visible-xs">
                  <Link href={item.link}><span className="btn">{item.title}</span></Link>
                </div>
              </div>
            )
          })}
        </AutoPlaySwipeableViews>
      </div>
    </div>
  )
}

export default Index
