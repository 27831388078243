import { uReq } from '@/utils'

function getNewsList (data: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/api/news/1.0/os/getList', data, ...options })
}

function getNewsDetail (data: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/api/news/1.0/os/getDetail', data, ...options })
}

function getNewsBanner (data: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/api/news/1.0/os/getNewsBanner', data, ...options })
}

function getBannerDetail (data: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/api/news/1.0/os/getBannerDetail', data, ...options })
}

// 门店计费信息
function getStoreBillInfo (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/crius/h5/shop/listShopInfo', method: 'POST', data, ...options })
}

// 代理加盟
function addRecruit (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/garen/channelleads/recruit/add', method: 'POST', data, ...options })
}

function sendAdForm (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/foxDspPlat/gw/mail/send', method: 'POST', data, ...options })
}

function getAreaform (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/virgo/getAllNew', method: 'GET', data, ...options })
}

function addMerchant (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/scrm/v2/leads/website/cooperate', method: 'post', data, ...options })
}

function getPositionPics (data?: Record<string, any>, options: Record<string, any> = {}): Promise<any> {
  return uReq({ url: '/api/1.0/pics/swiper/content', method: 'GET', data, ...options })
}

export default {
  getNewsList,
  getNewsDetail,
  getNewsBanner,
  getBannerDetail,
  getStoreBillInfo,
  addRecruit,
  sendAdForm,
  getAreaform,
  addMerchant,
  getPositionPics,
}
